import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useAdsListPrograms() {
  // Use toast
  const toast = useToast();

  const refAdListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    { key: "flow_origin", sortable: true },
    { key: "title", sortable: false },
    { key: "zip", sortable: true },
    { key: "price_min", sortable: true, tdClass: "limit-col-width-10" },
    { key: "price_max", sortable: true, tdClass: "limit-col-width-10" },
    {
      key: "nb_available_properties",
      label: "Available props",
      sortable: true,
    },
    { key: "area_min", sortable: true },
    { key: "area_max", sortable: true },
    { key: "nb_rooms_min", sortable: true },
    { key: "nb_rooms_max", sortable: true },
    { key: "latitude", label: "lat", sortable: true },
    { key: "longitude", label: "lng", sortable: true },
    { key: "enabled", sortable: true },
    { key: "delivery_date", sortable: true },
    { key: "integration_date", sortable: true },
  ];

  const flowOptions = ref([]);

  const nameQuery = ref("");
  const idQuery = ref("");
  const zipQuery = ref("");
  const perPage = ref(15);
  const totalAds = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [15, 30, 60, 120];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const isEnabled = ref(false);
  const minIntegrationDate = ref(undefined);
  const maxIntegrationDate = ref(undefined);
  const minDeliveryDate = ref(undefined);
  const maxDeliveryDate = ref(undefined);
  const flowFilter = ref(null);
  const typeParentFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAdListTable.value
      ? refAdListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAds.value,
    };
  });

  const refetchData = () => {
    refAdListTable.value.refresh();
  };

  watch(
    [
      nameQuery,
      idQuery,
      zipQuery,
      currentPage,
      perPage,
      searchQuery,
      minIntegrationDate,
      maxIntegrationDate,
      minDeliveryDate,
      maxDeliveryDate,
      isEnabled,
      flowFilter,
      typeParentFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchFlowsOrigins = () => {
    let queryParams = {
      typeOffers: "NEUF",
    };

    store
      .dispatch("app-ads/fetchFlowsOrigins", queryParams)
      .then((response) => {
        flowOptions.value = response.data.flows_origins;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching flows origins' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchAds = (ctx, callback) => {
    let queryParams = {
      nameQuery: nameQuery.value,
      idQuery: idQuery.value,
      zipQuery: zipQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      minIntegrationDate: minIntegrationDate.value,
      maxIntegrationDate: maxIntegrationDate.value,
      minDeliveryDate: minDeliveryDate.value,
      maxDeliveryDate: maxDeliveryDate.value,
      enabled: isEnabled.value,
    };

    if (flowFilter.value != null) queryParams.flow = flowFilter.value;

    store
      .dispatch("app-ads/fetchAdsPrograms", queryParams)
      .then((response) => {
        const ads = response.data.ads_programs;
        totalAds.value = response.data.count;
        callback(ads);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ads programs' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    // Options
    flowOptions,

    fetchAds,
    fetchFlowsOrigins,
    tableColumns,
    nameQuery,
    idQuery,
    zipQuery,
    perPage,
    currentPage,
    totalAds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    isEnabled,
    minIntegrationDate,
    maxIntegrationDate,
    minDeliveryDate,
    maxDeliveryDate,
    refAdListTable,

    refetchData,

    // Extra Filters
    flowFilter,
    typeParentFilter,
  };
}
