<template>
  <b-card no-body>
    <b-card-body class="py-1">
      <b-row>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Nom</label>
          <b-form-input
            id="name_like"
            placeholder="Le Domain d'Epona"
            @input="(val) => $emit('update:nameQuery', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>ID</label>
          <b-form-input
            id="id_like"
            placeholder="1523695"
            @input="(val) => $emit('update:idQuery', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Zip</label>
          <b-form-input
            id="zip_like"
            placeholder="69300"
            @input="(val) => $emit('update:zipQuery', val)"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Flux</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="flowFilter"
            :options="flowOptions"
            class="w-100"
            @input="(val) => $emit('update:flowFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="1" class="mb-md-0">
          <label>Enabled</label>
          <b-form-checkbox
            :checked="isEnabled"
            class="custom-control-primary"
            name="check-button"
            @input="(val) => $emit('update:isEnabled', val)"
            switch
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Min integration date</label>
          <flat-pickr
            :value="minIntegrationDate"
            @input="(val) => $emit('update:minIntegrationDate', val)"
            class="form-control"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Max integration date</label>
          <flat-pickr
            :value="maxIntegrationDate"
            @input="(val) => $emit('update:maxIntegrationDate', val)"
            class="form-control"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Min delivery date</label>
          <flat-pickr
            :value="minDeliveryDate"
            @input="(val) => $emit('update:minDeliveryDate', val)"
            class="form-control"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0">
          <label>Max delivery date</label>
          <flat-pickr
            :value="maxDeliveryDate"
            @input="(val) => $emit('update:maxDeliveryDate', val)"
            class="form-control"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormCheckbox,

    vSelect,
    flatPickr,
  },
  props: {
    idQuery: {
      type: [String, null],
      default: null,
    },
    zipQuery: {
      type: [String, null],
      default: null,
    },
    flowFilter: {
      type: [String, null],
      default: null,
    },
    isEnabled: {
      type: [Boolean, null],
      default: null,
    },
    minIntegrationDate: {
      type: [String, null],
      default: null,
    },
    maxIntegrationDate: {
      type: [String, null],
      default: null,
    },
    minDeliveryDate: {
      type: [String, null],
      default: null,
    },
    maxDeliveryDate: {
      type: [String, null],
      default: null,
    },
    flowOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
