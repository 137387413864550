<template>
  <b-modal
    id="modal-list-lots"
    content-class="modal-property-height"
    centered
    :hide-footer="true"
    size="xl"
  >
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
        ref="refAdListTable"
        class="position-relative"
        :items="fetchAds"
        responsive
        small
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Ad -->
        <template #cell(id)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label ml-50">{{ row.item.id }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col md="1">
                <b-avatar
                  v-if="!row.item.medias || !row.item.medias.length > 0"
                  :src="$config.VUE_APP_IMG_404"
                />
                <b-avatar v-else :src="row.item.medias[0].url" />
              </b-col>
              <b-col md="3">
                <strong>Title : </strong>{{ row.item.title }}
              </b-col>
              <b-col md="8">
                <strong>Description : </strong>{{ row.item.description }}
              </b-col>
              <b-col md="2"> <strong>ID : </strong>{{ row.item.id }} </b-col>
              <b-col md="2">
                <strong>Integration date : </strong
                >{{ row.item.integration_date }}
              </b-col>
              <b-col md="2">
                <strong>Update at : </strong>{{ row.item.update_at }}
              </b-col>
              <b-col md="2"> <strong>Age : </strong>{{ row.item.age }} </b-col>
              <b-col md="2">
                <strong>Service : </strong>{{ row.item.service }}
              </b-col>
              <b-col md="2">
                <strong>Mandate type : </strong>{{ row.item.mandate_type }}
              </b-col>
              <b-col md="2">
                <strong>Ubiflow ID : </strong>{{ row.item.ubiflow_ID }}
              </b-col>
              <b-col md="2">
                <strong>Developer ID : </strong>{{ row.item.developer_ID }}
              </b-col>
              <b-col md="2">
                <strong>Program ID : </strong>{{ row.item.program_ID }}
              </b-col>
              <b-col md="2">
                <strong>Availability : </strong>{{ row.item.availability }}
              </b-col>
              <b-col md="2">
                <strong>Availability date : </strong
                >{{ row.item.availability_date }}
              </b-col>
              <b-col md="2">
                <strong>Reference : </strong>{{ row.item.reference }}
              </b-col>
              <b-col md="2">
                <strong>Floor : </strong>{{ row.item.floor }}
              </b-col>
              <b-col md="2">
                <strong>Nb floors : </strong>{{ row.item.nb_floors }}
              </b-col>
              <b-col md="2">
                <strong>Year of construction : </strong
                >{{ row.item.year_of_construction }}
              </b-col>
              <b-col md="2">
                <strong>Virtual visit : </strong>{{ row.item.virtual_visit }}
              </b-col>
              <b-col md="2">
                <strong>Exposure : </strong>{{ row.item.exposure }}
              </b-col>
              <b-col md="2">
                <strong>Price : </strong>{{ row.item.price }}€
              </b-col>
              <b-col md="2">
                <strong>Rent : </strong>{{ row.item.rent }}€
              </b-col>
              <b-col md="2">
                <strong>Owner type : </strong>{{ row.item.owner_type }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Furniture price ttc : </strong
                >{{ row.item.furniture_price_ttc }}
              </b-col>
              <b-col md="2">
                <strong>Furniture price ht : </strong
                >{{ row.item.furniture_price_ht }}
              </b-col>
              <b-col md="2">
                <strong>Property price ttc : </strong
                >{{ row.item.property_price_ttc }}
              </b-col>
              <b-col md="2">
                <strong>Property price ht : </strong
                >{{ row.item.property_price_ht }}
              </b-col>
              <b-col md="2">
                <strong>Tax rate : </strong>{{ row.item.tax_rate }}
              </b-col>
              <b-col md="2">
                <strong>Profit rate : </strong>{{ row.item.profit_rate }}
              </b-col>
              <b-col md="2">
                <strong>Renovation rate : </strong
                >{{ row.item.renovation_rate }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Price excl fees : </strong
                >{{ row.item.price_excl_fees }}
              </b-col>
              <b-col md="2">
                <strong>Payer fees : </strong>{{ row.item.payer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Buyer fees : </strong>{{ row.item.buyer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Seller fees : </strong>{{ row.item.seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage buyer fees : </strong
                >{{ row.item.percentage_buyer_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage seller fees : </strong
                >{{ row.item.percentage_seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Percentage seller fees : </strong
                >{{ row.item.percentage_seller_fees }}
              </b-col>
              <b-col md="2">
                <strong>Charges : </strong>{{ row.item.charges }}
              </b-col>
              <b-col md="2">
                <strong>Guarantee deposit : </strong
                >{{ row.item.guarantee_deposit }}
              </b-col>
              <b-col md="2">
                <strong>Url public tariff : </strong
                >{{ row.item.url_public_tariff }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Area : </strong>{{ row.item.area }}
              </b-col>
              <b-col md="2">
                <strong>Stay area : </strong>{{ row.item.stay_area }}
              </b-col>
              <b-col md="2">
                <strong>Terrace area : </strong>{{ row.item.terrace_area }}
              </b-col>
              <b-col md="2">
                <strong>Balcony area : </strong>{{ row.item.balcony_area }}
              </b-col>
              <b-col md="2">
                <strong>Garden area : </strong>{{ row.item.garden_area }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Street : </strong>{{ row.item.street }}
              </b-col>
              <b-col md="2"> <strong>Zip : </strong>{{ row.item.zip }} </b-col>
              <b-col md="2">
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col md="2">
                <strong>Country : </strong>{{ row.item.country }}
              </b-col>
              <b-col md="2">
                <strong>Latitude : </strong>{{ row.item.latitude }}
              </b-col>
              <b-col md="2">
                <strong>Longitude : </strong>{{ row.item.longitude }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Nb rooms : </strong>{{ row.item.nb_rooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb bed rooms : </strong>{{ row.item.nb_bed_rooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb bathrooms : </strong>{{ row.item.nb_bathrooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb water rooms : </strong>{{ row.item.nb_water_rooms }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Kitchen : </strong>{{ row.item.kitchen }}
              </b-col>
              <b-col md="2">
                <strong>Terrace : </strong>{{ row.item.terrace }}
              </b-col>
              <b-col md="2">
                <strong>Furnished : </strong>{{ row.item.furnished }}
              </b-col>
              <b-col md="2">
                <strong>Cellar : </strong>{{ row.item.cellar }}
              </b-col>
              <b-col md="2">
                <strong>Elevator : </strong>{{ row.item.elevator }}
              </b-col>
              <b-col md="2">
                <strong>Fireplace : </strong>{{ row.item.fireplace }}
              </b-col>
              <b-col md="2">
                <strong>Veranda : </strong>{{ row.item.veranda }}
              </b-col>
              <b-col md="2">
                <strong>Garage : </strong>{{ row.item.garage }}
              </b-col>
              <b-col md="2">
                <strong>Swimming pool : </strong>{{ row.item.swimming_pool }}
              </b-col>
              <b-col md="2">
                <strong>Green spaces : </strong>{{ row.item.green_spaces }}
              </b-col>
              <b-col md="2">
                <strong>Loggia : </strong>{{ row.item.loggia }}
              </b-col>
              <b-col md="2">
                <strong>Balcony : </strong>{{ row.item.balcony }}
              </b-col>
              <b-col md="2">
                <strong>Private garden : </strong>{{ row.item.private_garden }}
              </b-col>
              <b-col md="2">
                <strong>Videocom : </strong>{{ row.item.videocom }}
              </b-col>
              <b-col md="2">
                <strong>Fibre : </strong>{{ row.item.fibre }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Heating type : </strong>{{ row.item.heating_type }}
              </b-col>
              <b-col md="2">
                <strong>Heating energy : </strong>{{ row.item.heating_energy }}
              </b-col>
              <b-col md="2">
                <strong>Heating mechanism : </strong
                >{{ row.item.heating_mechanism }}
              </b-col>
              <b-col md="2">
                <strong>Dpe label consumption : </strong
                >{{ row.item.dpe_label_consumption }}
              </b-col>
              <b-col md="2">
                <strong>Dpe value consumption : </strong
                >{{ row.item.dpe_value_consumption }}
              </b-col>
              <b-col md="2">
                <strong>Dpe label gaz : </strong>{{ row.item.dpe_label_gaz }}
              </b-col>
              <b-col md="2">
                <strong>Dpe value gaz : </strong>{{ row.item.dpe_value_gaz }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              class="mt-1"
              @click="row.toggleDetails"
            >
              Fermer
            </b-button>
          </b-card>
        </template>

        <template #cell(type_parent)="data">
          <b-badge
            pill
            :variant="`light-${resolveAdTypeParentVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(price)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(price_excl_fees)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(percentage_buyer_fees)="data">
          {{ data.value }}%
        </template>

        <template #cell(dpe_value_consumption)="row">
          {{ row.item.dpe_value_consumption }} -
          {{ row.item.dpe_label_consumption }}
        </template>

        <template #cell(dpe_value_gaz)="row">
          {{ row.item.dpe_value_gaz }} - {{ row.item.dpe_label_gaz }}
        </template>

        <template #cell(integration_date)="data">
          {{ data.value.slice(0, 10) }}
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BFormCheckbox,
  BButton,
  BBadge,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useAdsListLots from "./useAdsListLots";
import { currency } from "@core/utils/filter";

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BFormCheckbox,
    BButton,
    BBadge,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    programId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    programId(val) {
      this.programID = val;
    },
  },
  setup() {
    const {
      fetchAds,
      programID,
      tableColumns,

      // UI
      resolveAdTypeParentVariant,
    } = useAdsListLots();

    return {
      fetchAds,
      programID,
      tableColumns,

      // Filter
      avatarText,

      // UI
      resolveAdTypeParentVariant,

      currency,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
