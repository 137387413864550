import { ref } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useAdsListLots() {
  // Use toast
  const toast = useToast();

  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    { key: "flow_origin", label: "flow", sortable: true },
    { key: "flow_ID", sortable: true },
    { key: "zip", sortable: true },
    { key: "type_parent", sortable: true },
    { key: "price", sortable: true, tdClass: "limit-col-width-10" },
    {
      key: "price_excl_fees",
      label: "price - fees =",
      sortable: true,
      tdClass: "limit-col-width-10",
    },
    { key: "payer_fees", sortable: true },
    { key: "percentage_buyer_fees", label: "% fees", sortable: true },
    { key: "dpe_value_consumption", label: "dpe", sortable: true },
    { key: "dpe_value_gaz", label: "ges", sortable: true },
    { key: "latitude", label: "lat", sortable: true },
    { key: "longitude", label: "lng", sortable: true },
    { key: "enabled", sortable: true },
    { key: "integration_date", sortable: true },
  ];

  const programID = ref(0);

  const fetchAds = (ctx, callback) => {
    let queryParams = {
      id: String(programID.value),
    };

    store
      .dispatch("app-ads/fetchAdsProgramsLots", queryParams)
      .then((response) => {
        const ads = response.data.ads_programs_lots;
        callback(ads);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ads programs lots' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAdTypeParentVariant = (typeParent) => {
    if (typeParent === "apartment") return "primary";
    if (typeParent === "field") return "secondary";
    if (typeParent === "local") return "warning";
    if (typeParent === "house") return "success";
    if (typeParent === "parking") return "info";
    if (typeParent === "other") return "danger";
    return "primary";
  };

  return {
    fetchAds,
    programID,
    tableColumns,

    resolveAdTypeParentVariant,
  };
}
