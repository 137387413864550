<template>
  <div>
    <b-row v-if="$route.meta.pageTitle" class="content-header mt-1 ml-2">
      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Filters -->
    <ads-programs-list-filters
      :name-query.sync="nameQuery"
      :id-query.sync="idQuery"
      :zip-query.sync="zipQuery"
      :flow-filter.sync="flowFilter"
      :flow-options="flowOptions"
      :is-enabled.sync="isEnabled"
      :min-integration-date.sync="minIntegrationDate"
      :max-integration-date.sync="maxIntegrationDate"
      :min-delivery-date.sync="minDeliveryDate"
      :max-delivery-date.sync="maxDeliveryDate"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Montrer</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>annonces</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} résultats</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAds"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <ads-list-lots :program-id="programSelectedID"></ads-list-lots>

      <b-table
        ref="refAdListTable"
        class="position-relative"
        :items="fetchAds"
        responsive
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Ad -->
        <template #cell(id)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label ml-50">{{ row.item.id }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row>
              <b-col md="1">
                <b-avatar
                  v-if="!row.item.medias || !row.item.medias.length > 0"
                  :src="$config.VUE_APP_IMG_404"
                />
                <b-avatar v-else :src="row.item.medias[0].url" />
              </b-col>
              <b-col md="3">
                <strong>Title : </strong>{{ row.item.title }}
              </b-col>
              <b-col md="8">
                <strong>Description : </strong>{{ row.item.description }}
              </b-col>
              <b-col md="2"> <strong>ID : </strong>{{ row.item.id }} </b-col>
              <b-col md="2">
                <strong>Integration date : </strong
                >{{ row.item.integration_date }}
              </b-col>
              <b-col md="2">
                <strong>Update at : </strong>{{ row.item.update_at }}
              </b-col>
              <b-col md="2">
                <strong>Ubiflow ID : </strong>{{ row.item.ubiflow_ID }}
              </b-col>
              <b-col md="2">
                <strong>Developer ID : </strong>{{ row.item.developer_ID }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Ad URL : </strong>{{ row.item.ad_URL }}
              </b-col>
              <b-col md="6">
                <strong>Virtual visit : </strong>{{ row.item.virtual_visit }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col md="2"> <strong>Zip : </strong>{{ row.item.zip }} </b-col>
              <b-col md="2">
                <strong>Country : </strong>{{ row.item.country }}
              </b-col>
              <b-col md="2">
                <strong>Street : </strong>{{ row.item.street }}
              </b-col>
              <b-col md="2">
                <strong>Latitude : </strong>{{ row.item.latitude }}
              </b-col>
              <b-col md="2">
                <strong>Longitude : </strong>{{ row.item.longitude }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Delivery date : </strong>{{ row.item.delivery_date }}
              </b-col>
              <b-col md="2">
                <strong>Commercialisation date : </strong
                >{{ row.item.commercialisation_date }}
              </b-col>
              <b-col md="2">
                <strong>Offer start date : </strong
                >{{ row.item.offer_start_date }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Nb properties : </strong>{{ row.item.nb_properties }}
              </b-col>
              <b-col md="2">
                <strong>Nb available properties : </strong
                >{{ row.item.nb_available_properties }}
              </b-col>
              <b-col md="2">
                <strong>Elevator : </strong>{{ row.item.elevator }}
              </b-col>
              <b-col md="2">
                <strong>Area min : </strong>{{ row.item.area_min }}
              </b-col>
              <b-col md="2">
                <strong>Area max : </strong>{{ row.item.area_max }}
              </b-col>
              <b-col md="2">
                <strong>Price min : </strong>{{ row.item.price_min }}
              </b-col>
              <b-col md="2">
                <strong>Price max : </strong>{{ row.item.price_max }}
              </b-col>
              <b-col md="2">
                <strong>Nb rooms : </strong>{{ row.item.nb_rooms }}
              </b-col>
              <b-col md="2">
                <strong>Nb rooms min : </strong>{{ row.item.nb_rooms_min }}
              </b-col>
              <b-col md="2">
                <strong>Nb rooms max : </strong>{{ row.item.nb_rooms_max }}
              </b-col>
              <b-col md="2">
                <strong>Tax exemption : </strong>{{ row.item.tax_exemption }}
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2">
                <strong>Heating type : </strong>{{ row.item.heating_type }}
              </b-col>
              <b-col md="2">
                <strong>Heating energy : </strong>{{ row.item.heating_energy }}
              </b-col>
              <b-col md="2">
                <strong>Heating mechanism : </strong
                >{{ row.item.heating_mechanism }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
              class="mt-1"
            >
              Fermer
            </b-button>

            <b-button
              size="sm"
              class="ml-1 mt-1"
              variant="primary"
              @click="selectProgramID(row.item.id)"
              v-b-modal.modal-list-lots
            >
              Voir les lots
            </b-button>
          </b-card>
        </template>

        <template #cell(price_min)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(price_max)="data">
          {{ currency(data.value) }}
        </template>

        <template #cell(delivery_date)="data">
          {{ data.value.slice(0, 10) }}
        </template>

        <template #cell(integration_date)="data">
          {{ data.value.slice(0, 10) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BFormCheckbox,
  BButton,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import AdsProgramsListFilters from "../components/AdsProgramsListFilters.vue";
import AdsListLots from "../lots/AdsListLots.vue";
import useAdsListPrograms from "./useAdsListPrograms";
import adsStoreModule from "../adsStoreModule";
import { currency } from "@core/utils/filter";

export default {
  components: {
    AdsProgramsListFilters,
    AdsListLots,

    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BFormCheckbox,
    BButton,
    BPagination,

    vSelect,
  },
  data() {
    return {
      programSelectedID: 0,
    };
  },
  setup() {
    const ADS_APP_STORE_MODULE_NAME = "app-ads";

    // Register module
    if (!store.hasModule(ADS_APP_STORE_MODULE_NAME))
      store.registerModule(ADS_APP_STORE_MODULE_NAME, adsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADS_APP_STORE_MODULE_NAME))
        store.unregisterModule(ADS_APP_STORE_MODULE_NAME);
    });

    const {
      fetchAds,
      fetchFlowsOrigins,
      tableColumns,
      nameQuery,
      idQuery,
      zipQuery,
      perPage,
      currentPage,
      totalAds,
      dataMeta,
      perPageOptions,
      searchQuery,
      isEnabled,
      minIntegrationDate,
      maxIntegrationDate,
      minDeliveryDate,
      maxDeliveryDate,
      sortBy,
      isSortDirDesc,
      refAdListTable,
      refetchData,

      // Options
      flowOptions,

      // Extra Filters
      flowFilter,
      typeParentFilter,
    } = useAdsListPrograms();

    fetchFlowsOrigins();

    return {
      fetchAds,
      tableColumns,
      nameQuery,
      idQuery,
      zipQuery,
      perPage,
      currentPage,
      totalAds,
      dataMeta,
      perPageOptions,
      searchQuery,
      isEnabled,
      minIntegrationDate,
      maxIntegrationDate,
      minDeliveryDate,
      maxDeliveryDate,
      sortBy,
      isSortDirDesc,
      refAdListTable,
      refetchData,

      // Filter
      avatarText,

      flowOptions,

      // Extra Filters
      flowFilter,
      typeParentFilter,
      currency,
    };
  },
  methods: {
    selectProgramID(programID) {
      this.programSelectedID = programID;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
